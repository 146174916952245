<template>
  <canvas ref="chart"></canvas>
</template>
<script>
import {
  Chart,
  BarController,
  CategoryScale,
  LinearScale,
  BarElement
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";

export default {
  props: {
    daysInMonth: {
      type: Number,
      default: 30,
      required: false
    },
    monthlySales: {
      type: Array,
      default: () => {
        return [];
      }
    },
    avgSales: {
      type: [String, Number],
      default: "0"
    }
  },
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.renderChart();
    this.$root.$off("loadedTopPageData");
    this.$root.$on("loadedTopPageData", payload => {
      this.destroyChart();
      this.renderChart();
    });
  },
  computed: {
    chartLabels() {
      return Array.from({ length: this.daysInMonth }, (_, i) => i + 1);
    }
  },
  methods: {
    destroyChart() {
      this.chart.destroy();
    },
    renderChart() {
      Chart.register(
        BarController,
        CategoryScale,
        LinearScale,
        BarElement,
        annotationPlugin
      );
      this.chart = new Chart(this.$refs.chart, {
        type: "bar",
        options: {
          plugins: {
            annotation: {
              annotations: {
                line1: {
                  drawTime: "beforeDraw",
                  type: "line",
                  scaleID: "y",
                  borderWidth: 2,
                  borderColor: "#CCD7EC",
                  value: this.avgSales,
                  label: {
                    enabled: false,
                    content: "Info Label"
                  }
                }
              }
            }
          },
          elements: {
            bar: {
              borderRadius: 2.5
            }
          },
          scales: {
            x: {
              ticks: {
                maxTicksLimit: 8,
                color: "#333333"
              },
              grid: {
                display: false,
                borderColor: "#333333",
                offset: true
              }
            },
            y: {
              ticks: {
                display: false
              },
              grid: {
                display: false,
                borderColor: "#333333",
                offset: true
              }
            }
          }
        },
        data: {
          labels: this.chartLabels,
          datasets: [
            {
              label: "当日売上",
              data: Array.prototype.map.call(
                this.monthlySales,
                val => val.sales
              ),
              backgroundColor: Array.prototype.map.call(
                this.monthlySales,
                val => val.color
              )
            }
          ]
        }
      });
    }
  }
};
</script>
