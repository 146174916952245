<template>
  <v-row
    no-gutters
    class="rounded  my-1 pl-3 pb-3 pt-2"
    :style="{ background: backgroundColor, height: height }"
  >
    <v-col class="d-flex flex-column justify-center">
      <p class="subtitle" :class="spacingBelowTitle">売上構成比</p>
      <div
        v-for="(item, index) in data"
        :key="index"
        class="main-meal-bar d-flex align-center"
      >
        <!-- bar -->
        <div
          class="bar mr-1 mb-0 height-9px"
          :style="{ background: barColor, width: item.percentage + '%' }"
        ></div>
        <!-- icon -->
        <v-icon size="16px"> {{ item.icon }}</v-icon>
        <!-- percentage -->
        <div class="percentage ml-2 font-weight-bold font-12px">
          {{ item.percentage }}%
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    backgroundColor: {
      type: String,
      required: true
    },
    barColor: {
      type: String,
      default: "#6e77f0",
      required: true
    },
    barHeight: {
      type: String,
      required: false
    },
    data: {
      type: Array,
      required: true
    },
    width: {
      type: String,
      required: false,
      default: "148px"
    },
    height: {
      type: String,
      required: false,
      default: "148px"
    },
    iconSize: {
      type: String,
      required: false
    },
    fontSize: {
      type: String,
      required: false
    },
    innerBarSpacing: {
      type: String,
      required: false,
      default: "mt-1"
    },
    spacingBelowTitle: {
      type: String,
      required: false,
      default: ""
    }
  }
};
</script>
<style lang="scss" src="./budget.scss" scoped></style>
